<template>
  <el-drawer
       class="drawerCommon"
      :visible.sync="drawer"
      :wrapperClosable="false"
      size="900px" :with-header="false">
    <el-table
        :data="data" border
        :row-class-name="tableRowClassName"
        class="tableMember"
        :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        height="95%">
      <el-table-column label="性别" width="100">
        <template slot-scope="scope">
          <el-select v-model="animal.gender" v-if="scope.$index==0" size="small"
                     placeholder="性别">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <template v-else>
            <template v-if="scope.row.show">
              <el-select v-model="scope.row.gender" size="small" placeholder="性别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
            <span v-else>{{ scope.row.gender == 0 ? '♀' : '♂' }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="耳号"  show-overflow-tooltip>
        <template slot-scope="scope">
          <el-input v-model="animal.overbit" size="small" v-if="scope.$index==0"
                    placeholder="耳号"></el-input>
          <template v-else>
            <template v-if="scope.row.show">
              <el-input size="small" placeholder="耳号" v-model="scope.row.overbit"></el-input>
            </template>
            <span v-else>{{ scope.row.overbit }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="笼舍号" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-select v-if="scope.$index==0" v-model="animal.cageNo" size="small" placeholder="笼舍号"
          @change="selectNumberNo($event,scope.row,scope.$index)" >
            <el-option
                v-for="(item ,index) in cageList"
                :key="index"
                :label="item.number"
                :value="index">
                <el-button type="primary" v-if="index+1 == cageList.length">新增笼舍</el-button>
            </el-option>
          </el-select>
          <template v-else>
            <template v-if="scope.row.show">
              <el-select v-model="scope.row.cageNo" size="small" placeholder="笼舍号"
              @change="selectNumberNo($event,scope.row,scope.$index)">
                <el-option
                    v-for="(item,index) in cageList"
                    :key="index"
                    :label="item.number"
                    :value="index">
                </el-option>
              </el-select>
              <!-- <el-input size="small" placeholder="笼舍号" v-model="scope.row.cageNo"></el-input> -->
            </template>
            <span v-else>{{ scope.row.cageNo }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="笼架号">
        <template slot-scope="scope">
          <el-select v-if="scope.$index==0" v-model="animal.frameNo" size="small" placeholder="笼架号">
            <el-option
                v-for="(item,index) in cageList"
                :key="index"
                :label="item.frameNo"
                :value="item.frameNo">

            </el-option>
          </el-select>
          <template v-else>
            <template v-if="scope.row.show">
              <!-- <el-select v-model="scope.row.frameNo" size="small" placeholder="笼架号"></el-select>
                <el-option
                    v-for="item in cageList"
                    :key="item.frameNo"
                    :label="item.frameNo"
                    :value="item.frameNo">
                </el-option>
              </el-select> -->
              <el-input size="small" placeholder="笼架号" v-model="scope.row.frameNo"></el-input>
            </template>
            <span v-else>{{ scope.row.frameNo }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="父本耳号" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-input v-model="animal.foverbit" size="small" v-if="scope.$index==0"
                    placeholder="父本耳号"></el-input>
          <template v-else>
            <template v-if="scope.row.show">
              <el-input size="small" placeholder="父本耳号" v-model="scope.row.foverbit"></el-input>
            </template>
            <span v-else>{{ scope.row.foverbit }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="母本耳号" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-input v-model="animal.moverbit" size="small" v-if="scope.$index==0"
                    placeholder="母本耳号"></el-input>
          <template v-else>
            <template v-if="scope.row.show">
              <el-input size="small" placeholder="母本耳号" v-model="scope.row.moverbit"></el-input>
            </template>
            <span v-else>{{ scope.row.moverbit }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="出生日期" width="140">
        <template slot-scope="scope">
          <el-date-picker  style="width: 100%;"
              v-if="scope.$index==0"
              v-model="animal.birthTime" size="small"
              type="date"
              placeholder="出生日期">
          </el-date-picker>
          <template v-else>
            <template v-if="scope.row.show">
              <el-date-picker style="width: 100%;"
                  v-model="scope.row.birthTime" size="small"
                  type="date"
                  placeholder="出生日期">
              </el-date-picker>
            </template>
            <span v-else>{{ scope.row.birthTime | formatDay }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="备注" show-overflow-tooltip >
        <template slot-scope="scope">
          <el-input v-model="animal.remark" size="small" v-if="scope.$index==0"
                    placeholder="备注"></el-input>
          <template v-else>
            <template v-if="scope.row.show">
              <el-input size="small" placeholder="备注" v-model="scope.row.remark"></el-input>
            </template>
            <span v-else>{{ scope.row.remark }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template slot-scope="scope">
          <div v-if="scope.$index==0" class="addColor">
            <el-button type="text" @click="addTeam(scope.row,scope.$index)">添加</el-button>
            <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
            <el-button type="text" @click="reset">重置</el-button>
          </div>
          <div v-else class="editColor">
            <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
            <el-button type="text" @click="deleteX(scope.row,scope.$index)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button size="small" type="primary" @click="allSubmit">批量确认</el-button>
      <el-button size="small" plain @click="close">取消</el-button>
    </div>
    <div style="clear: both"></div>

    <!-- 换笼 -->
    <el-dialog
      title="新增笼舍"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="isShowAddCage"
      width="30%">
      <div class="dialog-text">
        <el-table
        :data="cageList"
        style="width: 100%">
          <el-table-column
            prop="date"
            label="笼舍编号">
            <template slot-scope="scope" v-if="scope.$index+1 < cageList.length">
              <el-input v-if="scope.$index==0" size="small" placeholder="请输入笼舍编号" v-model="cage.numberNo"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入笼舍编号" v-model="scope.row.number"></el-input>
                </template>
                <span v-else>{{scope.row.number}}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="笼架编号">
            <template slot-scope="scope" v-if="scope.$index+1 < cageList.length">
              <template v-if="scope.$index==0">
                <!-- <el-select  v-model="cage.frameNo" filterable size="small" placeholder="笼架号"></el-select>
                  <el-option
                      v-for="item in cageList"
                      :key="item.frameNo"
                      :label="item.frameNo"
                      :value="item.frameNo">
                  </el-option>
                </el-select> -->
                <el-input v-if="scope.$index==0" size="small" placeholder="请输入笼架编号" v-model="cage.frameNo"></el-input>
              </template>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入笼架编号" v-model="scope.row.frameNo"></el-input>
                </template>
                <span v-else>{{scope.row.frameNo}}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作">
            <template slot-scope="scope" v-if="scope.$index+1 < cageList.length">
              <div v-if="scope.$index==0" class="addColor">
                <el-button type="text" @click="addCage(scope.row,scope.$index)">添加</el-button>
                <el-button type="text" @click="resetCage">重置</el-button>
              </div>
              <div v-else class="addColor">
                <el-button type="text"  @click="editCage(scope.row,scope.$index)">{{scope.row.show ? '编辑' : '保存'}}</el-button>
                <!-- <el-button type="text" @click="delCage(scope.row)">删除</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCageDialog">取 消</el-button>
        <el-button type="primary" class="saveBtn" @click="closeCageDialog">确 定</el-button>
      </span>
    </el-dialog>
  </el-drawer>

</template>

<script>
  import { formatTimer } from '../../../../api/timezone.js'

//表格初始
const tableBegin = [
  {
    gender: "",
    name: "",
    overbit: "",
    cageNo: "",
    frameNo: "",
    foverbit:"",
    moverbit:"",
    birthTime: "",
    cageId:""
  }
]

export default {
  name: "removeMice",
  props: ['cageId','subId','isProd','isLabAni'],
  data() {
    return {
      drawer: false,
      data: [],
      animal: {
        gender: "",
        overbit: "",
        cageNo: "",
        frameNo: "",
        foverbit:"",
        moverbit:"",
        birthTime: "",
        cageId:""
      },
      options: [{
        value: 0,
        label: '雌性'
      }, {
        label: '雄性',
        value: 1
      }],
      aniMateBabyList:[],//新生小鼠分笼信息
      cage:{
        numberNo:'',
        frameNo:''
      },
      cageList:[],//
      isShowAddCage:false,//添加笼舍弹框
    }
  },
  created() {
    this.getCageList();
    // 项目成员,表格初始
    this.data = tableBegin
    this.data.map(i => {
      i.show = false
      return i
    })
  },
  mounted() {
  },
  methods: {
    //笼舍数据
    getCageList(){
      this.$get("/subject/cage/"+this.subId,{isLabAni:this.isLabAni}).then(res => {
        if(res.status == 200){
          this.cageList = res.data;
          this.cageList.push({})
        }else {
          this.$message.error("获取笼舍数据失败")
        }
      })
    },
    // //笼架数据
    // getCageframe(){
    //   this.$get("/subject/cage/mate/"+this.subId,{isLabAni:this.isLabAni}).then(res => {
    //     if(res.status == 200){
    //       this.cageFrameList = res.data;
    //     }else {
    //       this.$message.error("获取笼舍数据失败")
    //     }
    //   })
    // },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    // 添加
    addTeam(row,index) {
      if(this.animal.gender === ""){
        this.$message.warning('请选择性别！');
        return false;
      }
      if(this.animal.overbit === ""){
        this.$message.warning('请输入耳号！');
        return false;
      }
      if(this.animal.cageNo === ""){
        this.$message.warning('请输入笼舍编号！');
        return false;
      }
      if(this.animal.frameNo === ""){
        this.$message.warning('请输入笼架编号！');
        return false;
      }

      //接口添加，生产方案id不能为空，cageId不为空
      let params = {
        cageId: row.cageId,
        subId: this.subId,
        gender: this.animal.gender,
        overbit: this.animal.overbit,
        frameNo: this.animal.frameNo,
        cageNo: this.animal.cageNo,
        foverbit: this.animal.foverbit,
        moverbit: this.animal.moverbit,
        birthTime: this.animal.birthTime,
        remark:this.animal.remark,
      }
      this.data.push(params);
      // console.log(params,'params')
      this.$postJson("/mate/baby",params).then(res => {
      }).catch(() => {
        this.$message.error("获取数据失败")
      })
    },

    //选择,笼舍号，新增
    selectNumberNo(ev,row,index){
      this.animal.cageNo = this.cageList[ev].number
      row.cageId = this.cageList[ev].id
      this.$set(this.data, index, row)
      //判断点击的是否，新增笼舍
      if(ev+1 == this.cageList.length){
        this.cageList.forEach((item,index) => {
          item.show = false;
        })
        this.cageList.unshift({
          numberNo:'',
          frameNo:'',
          show:true
        })
        this.isShowAddCage = true;//打开新增笼舍弹框
      }
    },
    //确定添加，笼舍号
    addCage(row,index){
      if (this.cage.numberNo == '') {
        this.$message.warning("请先输入笼舍编号！")
        return;
      }
      if (this.cage.frameNo == '') {
        this.$message.warning("请先输入笼架编号！")
        return;
      }
      let params = {
        number: this.cage.numberNo,
        subId: this.subId,//课题id
        isProduct: this.isProd,//0：实验方案，1：生产方案
        frameNo: this.cage.frameNo
      }
      //接口新增
      this.$post("/subject/cage", params).then(res => {
        this.cageList.push(row)
        //初始化
        this.resetCage();
      }).catch(() => {
        this.$message.error("添加失败")
      })
    },
    //编辑，新增笼舍
    editCage(row,index){
      row.show = row.show ? false : true
      this.$set(this.cageList, index, row)
    },
    //重置，新增笼舍
    resetCage(){
      this.cage = {
        numberNo:'',
        frameNo:''
      }
    },
    //关闭,新增笼舍
    closeCageDialog(){
      this.cageList.splice(0,1);
      this.isShowAddCage = false;
      this.resetCage();//初始
    },

    // 重置
    reset() {
      this.animal = tableBegin;
    },
    // 复制
    copy(val, index) {
      this.data.splice(index, 0, JSON.parse(JSON.stringify(val)))

    },
    // 删除
    deleteX(row,index) {
      this.data.splice(index, 1)
      // this.$put("/mate/aniCage/detail/"+this.nowCageId,{handle:type}).then(res => {
      //   this.$message.success("操作成功！");

      // }).catch(() => {
      //   this.$message.error("操作失败，请重试！")
      // })
    },
    //取消,清空
    close(){
      this.drawer = false;
      this.animal = {
        gender: "",
        overbit: "",
        cageNo: "",
        frameNo: "",
        foverbit:"",
        moverbit:"",
        birthTime: "",
        isPrincipal: 0
      }
    },
    //批量提交
    allSubmit(){
      // this.drawer = false;
      let aniMateBabyList = [];
      let reg=new RegExp('/','g')//g代表全部
      this.data.forEach((item,index) => {
        if(index > 0){
          item.subId = this.subId;
          item.birthTime = formatTimer(item.birthTime).replace(reg,'-')
          aniMateBabyList.push(item)
        }
      })
      // console.log(aniMateBabyList,'aniMateBabyList')
      this.$postJson("/mate/baby/batch",aniMateBabyList).then(res => {
        this.$message.success("批量保存成功！")
        this.drawer = false;
      }).catch(() => {
        this.$message.error("保存失败")
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height:40px;
}
</style>
